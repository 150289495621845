<template>
  <div class="top-bar">
    <nav>
      <ul class="nav clearfix fl">
        <li class="nav-logo">
          <h1 class="logo">
            <img src="../assets/logo.png" alt="悦享" />
          </h1>
        </li>
        <li class="addreport">
          <router-link to="addreport">
            <span :class="{active:active=='addreport'}" @click="activation('addreport')">新建</span>
          </router-link>
        </li>
        <li class="sendrecord">
          <router-link to="sendrecord">
            <span :class="{active:active=='sendrecord'}" @click="activation('sendrecord')">发送记录</span>
          </router-link>
        </li>
        <li class="help">
          <router-link to="help">
            <span :class="{active:active=='help'}" @click="activation('help')">帮助中心</span>
          </router-link>
        </li>
      </ul>
      <div class="my fr">
        <el-dropdown trigger="click" @visible-change="arrowChange">
          <span class="el-dropdown-link">
            {{userInfo.realName}}
            <i class="el-icon-arrow-down el-icon--right" v-if="arrow"></i>
            <i v-else class="el-icon-arrow-up el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <router-link to="my">
                <span :class="{active:active=='my'}" @click="activation('my')">个人中心</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="students">
                <span :class="{active:active=='students'}" @click="activation('students')">学生管理</span>
              </router-link>
            </el-dropdown-item>

            <el-dropdown-item>
              <router-link to="drafts">
                <span :class="{active:active=='drafts'}" @click="activation('drafts')">草稿箱</span>
              </router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <span @click="logout">退出</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </nav>
  </div>
</template>
<script>
import http from "@/http/api";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      active: "addreport",
      arrow: true,
      userInfo: {
        id: "",
        mobile: "",
        orgId: "",
        orgName: "",
        orgType: "",
        realName: "",
        username: "",
      },
    };
  },
  created() {
    this.active = this.$route.name.toLowerCase();
    // 获取用户个人信息
    this.getUserInfo();
  },
  mounted() {},
  methods: {
    // 登出方法
    logout() {
      window.localStorage.removeItem("Authorization");
      this.$router.push("/login");
    },
    // 菜单激活项切换
    activation(name) {
      this.active = name;
    },
    arrowChange(val) {
      this.arrow = !val;
    },
    // 获取用户个人信息
    async getUserInfo() {
      const data = await http.getUserInfo();
      if (data.status == 200) {
        this.userInfo = data.data;
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.top-bar {
  height: 80px;
  width: 100vw;
  min-width: 1180px;
  background-color: #fff;
  nav {
    height: 100%;
    .nav {
      height: 100%;
      margin-left: 19px;
      li {
        height: 100%;
        float: left;
        line-height: 80px;
        margin-right: 69px;
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 400;
        color: #6d7278;
        a {
          display: inline-block;
          height: 100%;
          margin: auto 10px;
          box-sizing: content-box;
        }
      }
      .active {
        display: inline-block;
        font-weight: 700 !important;
        height: calc(100% - 3px);
        border-bottom: 3px solid #eb6123;
      }
      .nav-logo {
        line-height: 80px;
        text-align: center;
        .logo {
          display: inline-block;
          height: 64px;
          width: 202px;
          margin: 8px 0;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
    .my {
      width: 188px;
      height: 48px;
      margin: 18px 18px 10px 0;
      background-color: #fff;
      border: 2px solid #eee;
      border-radius: 5px;
      box-sizing: border-box;
      z-index: 2222;
      .el-dropdown {
        width: 100%;
        height: 100%;
        span {
          box-sizing: border-box;
          display: block;
          width: 100%;
          text-align: left;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #838f99;
          height: 100%;
          line-height: 46px;
          padding-left: 10px;
          i {
            float: right;
            margin-top: 14px;
            margin-right: 5px;
            transition: all linear 0.5s;
          }
        }
      }
    }
  }
}
::v-deep.el-dropdown-menu {
  box-sizing: border-box;
  margin-top: 0px;
  margin-right: -2px;
  width: 188px;
  .el-dropdown-menu__item {
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 700;
    color: #2c2d3a;
    span {
      display: block;
    }
    &:hover {
      color: #eb6123 !important;
      background: rgba($color: #eb6123, $alpha: 0.1) !important;
      a {
        color: #eb6123 !important;
        background: rgba($color: #eb6123, $alpha: 0.1) !important;
      }
    }
  }
  .active {
    color: #eb6123 !important;
  }
  .popper__arrow {
    opacity: 0;
  }
}
</style>
