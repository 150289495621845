<template>
  <div id="root">
    <topTar :key="$route.name" />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>
<script>
import topTar from "../components/topBar";
export default {
  name: "",
  props: {},
  components: { topTar },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
#root {
  min-width: 1366px;
  .content {
    min-height: 820px;
  }
}
</style>
>
